import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["expandTrigger", "sidebar", "hamburgerMenu", "closeTrigger", "navLinks", "moreNavItem", "moreNavDropdown"];

    connect() {
        // Collapse or expand sidebar based on local storage
        const sidebarState = localStorage.getItem('sidebarState');
        if (sidebarState === 'collapsed') {
            document.body.classList.add('sidebar-collapsed');
        }
        this.sidebarTarget.classList.remove('hide');

        // Adjust number of sidebar items and items in the "More" dropdown based on window height
        this.hiddenItems = [];
        this.hideAndShowItems();
        window.addEventListener('resize', this.handleResize.bind(this));
    }

    disconnect() {
        this.hiddenItems = [];
        window.removeEventListener('resize', this.handleResize.bind(this));
    }

    handleResize() {
        this.hideAndShowItems();
    }

    toggleExpand(event) {
        event.stopPropagation();
        document.body.classList.toggle('sidebar-collapsed');
        const isCollapsed = document.body.classList.contains('sidebar-collapsed');
        localStorage.setItem('sidebarState', isCollapsed ? 'collapsed' : 'expanded');
    }

    collapseSidebarIfMobile(event) {
        const screenWidth = window.innerWidth;
        if (screenWidth < 575.98) {
            document.body.classList.add('sidebar-collapsed');
            localStorage.setItem('sidebarState', 'collapsed');
        }
    }

    hideAndShowItems() {
        const items = this.sidebarTarget.querySelectorAll('.sidebar-nav-item:not([style*="display: none"])');
        const availableNumberOfItems = this.setNumberOfSidebarItems(window.innerHeight);

        if (items.length > availableNumberOfItems) {
            const itemsToRemove = items.length - availableNumberOfItems;
            const itemsToRemoveArray = Array.from(items).slice(-itemsToRemove).reverse();

            itemsToRemoveArray.forEach(item => {
                item.style.display = 'none';
                this.hiddenItems.unshift(item);
            });
        } else if (this.hiddenItems.length > 0) {
            const spaceAvailable = availableNumberOfItems - items.length;

            for (let i = 0; i < spaceAvailable && this.hiddenItems.length > 0; i++) {
                const itemToShow = this.hiddenItems.shift();
                itemToShow.style.display = 'block';
            }
        }

        this.buildMoreNavLinks();
        this.moreNavItemTarget.style.display = this.moreNavDropdownTarget.innerHTML != '' ? 'block' : 'none';

    }
    setNumberOfSidebarItems(windowHeight) {
        const topElementHeight = 250;
        const bottomElementHeight = 80;
        const availableSpace = windowHeight - topElementHeight - bottomElementHeight;
        const itemHeight = 43.2;
        const numberOfItems = Math.floor(availableSpace / itemHeight);
        return numberOfItems;
    };

    buildMoreNavLinks() {
        this.moreNavDropdownTarget.innerHTML = '';
        this.hiddenItems.forEach(item => {
            const sidebarItem = item.children[0];
            if (item.id !== 'more-nav-item') {
                const icon = sidebarItem.children[0].innerHTML;
                const text = sidebarItem.children[1].innerText;
                const moreNavLink = document.createElement('a');
                moreNavLink.className = 'dropdown-item';
                moreNavLink.target = sidebarItem.target;
                moreNavLink.href = sidebarItem.href;
                moreNavLink.innerHTML = `<span class="more-nav-icon">${icon}</span> ${text}`;
                this.moreNavDropdownTarget.appendChild(moreNavLink);
            }
        });
    }

}
