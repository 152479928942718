import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["selectAll", "rowCheckbox"]

  connect() {
  }

  toggleAll() {
    const isChecked = this.selectAllTarget.checked
    this.rowCheckboxTargets.forEach(checkbox => {
      checkbox.checked = isChecked
    })
  }

  rowChecked() {
    const allChecked = this.rowCheckboxTargets.every(checkbox => checkbox.checked)
    this.selectAllTarget.checked = allChecked
  }
}
