import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["folder", "toggleText", "toggleIcon"]

  connect() {
    this.isExpanded = true
    this.setupResizeObserver()
    this.handleVisibility()
    window.addEventListener('resize', () => this.handleVisibility())
  }

  disconnect() {
    window.removeEventListener('resize', () => this.handleVisibility())
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  }

  setupResizeObserver() {
    if (this.folderTargets.length > 0) {
      this.resizeObserver = new ResizeObserver(() => this.handleVisibility())
      this.resizeObserver.observe(this.folderTargets[0])
    }
  }

  handleVisibility() {
    if (this.folderTargets.length === 0) return

    const containerWidth = this.element.querySelector('.d-flex.flex-wrap').offsetWidth + 24
    const folderWidth = this.folderTargets[0].offsetWidth + 24
    const foldersPerRow = Math.floor(containerWidth / folderWidth)
    const visibleCount = window.innerWidth < 768 ? 1 : foldersPerRow
    const hiddenCount = this.folderTargets.length - visibleCount

    if (this.folderTargets.length > visibleCount) {
      this.toggleTextTarget.classList.remove('d-none')
      if (!this.isExpanded) {
        this.hideExtraFolders(visibleCount, hiddenCount)
      }
    } else {
      this.toggleTextTarget.classList.add('d-none')
      this.showAllFolders()
    }
  }

  toggle(event) {
    event.preventDefault()
    this.isExpanded = !this.isExpanded

    if (this.isExpanded) {
      this.showAllFolders()
    } else {
      this.handleVisibility()
    }
  }

  hideExtraFolders(visibleCount, hiddenCount) {
    this.folderTargets.forEach((folder, index) => {
      if (index >= visibleCount) {
        folder.classList.add('d-none')
      } else {
        folder.classList.remove('d-none')
      }
    })
    this.toggleTextTarget.innerHTML = `Show ${hiddenCount} More <i class="fas fa-chevron-down ms-2" data-folders-target="toggleIcon"></i>`
  }

  showAllFolders() {
    this.folderTargets.forEach(folder => {
      folder.classList.remove('d-none')
    })
    this.toggleTextTarget.innerHTML = `Show Less <i class="fas fa-chevron-up ms-2" data-folders-target="toggleIcon"></i>`
  }
}
