import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tags-overflow"
export default class extends Controller {
  static targets = ["container", "tag"]

  connect() {
    console.log('Tags overflow controller connected')
    this.handleTagsOverflow()
    // Recall the function on purpose
    this.handleTagsOverflow()
  }

  handleTagsOverflow() {
    const container = this.containerTarget
    const tags = this.tagTargets
    let containerWidth = container.offsetWidth
    let totalWidth = 0
    let overflowCount = 0
    let lastVisibleTag = null

    tags.forEach(tag => {
      const tagWidth = tag.offsetWidth + parseInt(getComputedStyle(tag).marginRight)

      if (totalWidth + tagWidth > containerWidth) {
        tag.style.display = 'none'
        overflowCount++
      } else {
        totalWidth += tagWidth
        lastVisibleTag = tag
      }
    })

    if (overflowCount > 0 && lastVisibleTag) {
      const overflowTag = document.createElement('span')
      overflowTag.className = 'profile-card-tag-overflow rounded-pill'
      overflowTag.textContent = `+${overflowCount}`
      overflowTag.style.width = 'fit-content'
      container.appendChild(overflowTag)

      const potentialPosition = lastVisibleTag.offsetLeft + lastVisibleTag.offsetWidth + 8
      if (potentialPosition + overflowTag.offsetWidth > containerWidth && lastVisibleTag.previousElementSibling) {
        lastVisibleTag.style.display = 'none'
        overflowCount++
        overflowTag.textContent = `+${overflowCount}`
        lastVisibleTag = lastVisibleTag.previousElementSibling
      }

      container.removeChild(overflowTag)
      lastVisibleTag.after(overflowTag)
      overflowTag.style.left = `${lastVisibleTag.offsetLeft + lastVisibleTag.offsetWidth + 8}px`
      overflowTag.style.top = `${lastVisibleTag.offsetTop}px`
    }
  }
}
